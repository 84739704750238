import { endsWith, eq, filter, find, gt, gte, includes, isEmpty, lt, lte, startsWith, toLower, trim } from "lodash";
import textHelper from "./textHelper";
import dateHelper from "./dateHelper";
import { toObjectInsert } from "./exportHelper";

declare type QueryType = "string" | "numeric" | "datetime" | "date" | "time" | "position";
declare type RuleType = "eq" | "contains" | "starts" | "ends" | "gte" | "gt" | "lte" | "lt";

class FormatHelper {

    getFlatingDataSurvey(peoples:any[],responses:any[],users:any[],current:any):any[]{
      var data : any[] = [];
      filter(peoples,{"investigation_id":current.id})
      .map((p)=>{
        var reps = filter(filter(responses,{"investigation_id":current.id}),{"people_id":p.id});
        const v = toObjectInsert(reps,this.getPeople(p.sections.length > 0, p, users,filter(responses,{"investigation_id":current.id})));
        data.push(v);
      });

      return data;
    }

    getFlatingDataSurveyAll(peoples:any[],responses:any[],users:any[]):any[]{
      var data : any[] = [];
      peoples
      .map((p)=>{
        var reps = filter(responses,{"people_id":p.id});
        const v = toObjectInsert(reps,this.getPeople(p.sections.length > 0, p, users,responses));
        data.push(v);
      });

      return data;
    }

    

    getPeople(hasSection:boolean,people:any,users:any[],reponses:any[]):any{
      return hasSection ? {
        "id":people.id,
        "fiche":people.form,
        "gender":people.gender,
        "phone":people.phone,
        "province":people.province,
        "common":people.common,
        "quarter":people.quarter,
        "position":people.latitude + ", "+people.longitude,
        "prefecture":people.prefecture,
        "sub_prefecture":people.sub_prefecture,
        "survey_object_name":people.survey_object_name,
        "user": textHelper.getNameUser(filter(users,{"id":people.user_id}))[0],
        "created_at":dateHelper.toDateAndTime(people.created_at),
        "photo":people.photo,
        "section":people.sections,
        "people_id":reponses,
        "investigation_id":people.investigation_id,
        "action":people
      } : 
      {
        "id":people.id,
        "fiche":people.form,
        "gender":people.gender,
        "phone":people.phone,
        "province":people.province,
        "common":people.common,
        "quarter":people.quarter,
        "position":people.latitude + ", "+people.longitude,
        "prefecture":people.prefecture,
        "sub_prefecture":people.sub_prefecture,
        "survey_object_name":people.survey_object_name,
        "user": textHelper.getNameUser(filter(users,{"id":people.user_id}))[0],
        "created_at":dateHelper.toDateAndTime(people.created_at),
        "photo":people.photo,
        "people_id":reponses,
        "investigation_id":people.investigation_id,
        "action":people
      }
    }

    reformObject(research:any):any{
        return {
            "column":research.column_id,
            "table":research.table_id,
            "type":research.type,
            "rule":research.rule
        };
    }

    getAnalyticsByKey(peoples:any[],column:string,type:QueryType,rule:RuleType,values:any[],name:string):number{
      return filter(peoples,(p)=>{
         if(startsWith(toLower(name),"autre") || startsWith(toLower(name),"autres")){
            return !values.some(prefix => startsWith(p[column],prefix));
         }
         else{
          return includes(values.map((v)=>this.ruleQueryValue(p[column],v,type,rule)),true);
         }
      })?.length;
    }

    getAnalyticsByVille(peoples:any[],column:string,type:QueryType,rule:RuleType,value:any[]):number{
      return filter(peoples,(p)=>{
        return this.ruleQueryValue(p[column],value,type,rule);
      })?.length;
    }

    filterByQuery(peoples:any[],subject:any,valueFilters:any[]):any[]|null{
        return filter(peoples,(p)=>{
            return valueFilters.length ===0 ? true : 
            includes(this.opByQuery(valueFilters,p,subject.column,subject.type,subject.rule),true);
        });
    }

    opByQuery(valueFilters:any[],people:any,column:string,type:QueryType,rule:RuleType):boolean[]{
        return valueFilters.map((v)=>this.ruleQuery(people[column],v,type,rule));
    }

    /** RULE */

    ruleQuery(value:any,filters:any[],type:QueryType,rule:RuleType):boolean{
        if(this.checkTypeForRule(type,rule)){
            return this.regexp(value,filters,rule);
        }

        return false;
    }

    ruleQueryValue(value:any,filter:any,type:QueryType,rule:RuleType):boolean{
      if(this.checkTypeForRule(type,rule)){
          return this.regexpValue(value,filter,rule);
      }

      return false;
    }

    /** FIN */

    checkTypeForRule(type:QueryType,rule:RuleType){
        switch (type) {
            case "string":
                return ["eq","contains","starts","ends"].includes(rule);
            case "numeric":
                return ["gte","gt","lte","lt"].includes(rule);
            default:
                return false;
        }
    }

    /** EXPRESSION REGULIERE */

    regexpValue(value:any,filter:any,rule:RuleType):boolean{
      value = !isEmpty(value) ? value : "";
      switch (rule) {
          case "starts":
            return startsWith(trim(value),filter);
          case "ends":
            return endsWith(trim(value),filter);
          case "contains":
            return includes(trim(value),filter);
          case "eq":
            return eq(value,filter);
          case "gte":
            return gte(filter,value);
          case "gt":
            return gt(filter,value);
          case "lte":
            return lte(filter,value);
          case "lt":
            return lt(filter,value);
          default:
            return false;
      }
    }

    regexp(value:any,filters:any[],rule:RuleType):boolean{
        value = !isEmpty(value) ? value : "";
        switch (rule) {
            case "starts":
              return filters.some(prefix => startsWith(trim(value),prefix));
            case "ends":
              return filters.some(prefix => endsWith(trim(value),prefix));
            case "contains":
              return filters.some(prefix => includes(trim(value),prefix));
            case "eq":
              return filters.some(prefix => eq(prefix,value));
            case "gte":
              return filters.some(prefix => gte(prefix,value));
            case "gt":
              return filters.some(prefix => gt(prefix,value));
            case "lte":
              return filters.some(prefix => lte(prefix,value));
            case "lt":
              return filters.some(prefix => lt(prefix,value));
            default:
              return false;
        }
    }

    /** FIN */


    getStatByKey(peoples:any[],reponses:any[],filterIds:any[],column:string,table:string,value:any):number{
      switch (table) {
        case "quizzes":
           if(this.getSortValue(filterIds))
           {
            return peoples.filter((p)=> includes(this.getFilter(reponses,column,value),p['id']) && includes(filterIds,p['id']))?.length;
           }
           else
           {
            return peoples.filter((p)=> includes(this.getFilter(reponses,column,value),p['id']))?.length;
           }
        case "people":
          if(this.getSortValue(filterIds))
          {
            return peoples.filter((p)=> startsWith(p[column],value)  && includes(filterIds,p['id']))?.length;
          }
          else
          {
            return peoples.filter((p)=> startsWith(p[column],value))?.length;
          }
        default:
          return 0;
      }
    }

    getFilter(reponses:any[],column:string,value:any):any[]{
      return reponses.filter(
        (r)=> r['quiz_id']==column && startsWith(r['content'],value)
      )
    }

    getTreatmentLengthByKey(treatments:any[],filterIds:any[],column:string,table:string,value:any):number{
      switch (table) {
        case "quizzes":
           if(this.getSortValue(filterIds))
           {
            return treatments.filter((t)=> startsWith(t[`q_${column}`],value) && includes(filterIds,t['id']))?.length;
           }
           else
           {
            return treatments.filter((t)=> startsWith(t[`q_${column}`],value))?.length;
           }
        case "people":
          if(this.getSortValue(filterIds))
          {
            return treatments.filter((t)=> startsWith(t[column],value) && includes(filterIds,t['id']))?.length;
          }
          else
          {
            return treatments.filter((t)=> startsWith(t[column],value))?.length;
          }
        default:
          return 0;
      }
    }

    getSortValue(filterIds:any[]):boolean{
      return filterIds.length > 0;
    }

    getColorLegende(legendes:any[],people:any,treatments:any[]):string|null{
        const element = legendes.length > 0 ? legendes[0] : null;

        if(element==null){
          return "#45b3d5";
        }

        if(element.table_id==="quizzes")
        {
          const columnValue = element.column_id;
          const item = find(treatments,{'id':people.id});
          const qColumnValue = item[`q_${columnValue}`];

          for (const content of element.contents) {
            if (startsWith(qColumnValue,content.name)) {
              return content.color;
            }
          }
        }
        else if(element.table_id==="people")
        {
          const columnValue = element.column_id;
          const item = find(treatments,{'id':people.id});
          const qColumnValue = item[columnValue];

          for (const content of element.contents) {
            if (startsWith(qColumnValue,content.name)) {
              return content.color;
            }
          }
        }

      return "#45b3d5";
    }

    getInfoBulle(legendes:any[],people:any,treatments:any[]):any|null{
      const element = legendes.length > 0 ? legendes[0] : null;

      if(element==null){
        return {"title":"","value":""};
      }

      if(element.table_id==="quizzes")
      {
        const columnValue = element.column_id;
        const item = find(treatments,{'id':people.id});
        const qColumnValue = item[`q_${columnValue}`];
  
        for (const content of element.contents) {
          if (startsWith(qColumnValue,content.name)) {
            return {"title":element.title,"value":content.name};
          }
        }
      }
      else if(element.table_id==="people")
      {
        const columnValue = element.column_id;
        const item = find(treatments,{'id':people.id});
        const qColumnValue = item[columnValue];
  
        for (const content of element.contents) {
          if (startsWith(qColumnValue,content.name)) {
            return {"title":element.title,"value":content.name};
          }
        }
      }
      
    return {"title":"","value":""};
  }

  getListForTableSimple(users:any[],peoples:any[]):any[]{
    return users.map((user)=>({
      "first_name":user.first_name,
      "last_name":user.last_name,
      "gender":user.gender,
      "phone":user.phone,
      "email":user.email,
      "nbre":filter(peoples,{"user_id":user.id})?.length
    }))?.sort((a, b) => b.nbre - a.nbre)
  }

}

export default new FormatHelper();