import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotFoundScreen } from "./layouts/404";
import { HomeLayout } from "./layouts/home/index";
import { MeLayout } from "./layouts/me/index";
import {SignInPage} from "./screens/signin";

function Main(props) {
  return (
      <React.Fragment>
        <Routes>
          <Route element={<HomeLayout />} path="home/*" />
          <Route element={<MeLayout />} path="me/*" />
          <Route path="/" element={<HomeLayout />} />
          <Route path="*" element={<SignInPage />} />
        </Routes>
      </React.Fragment>
  );
}

export default Main;

/**
 * 
import { socketConnect } from 'socket.io-react';
 * export default socketConnect(Main);
 * React.useEffect(()=>{
    realtimeHelper.listenSocket(props.socket, setSnapValue, dispatch);
    return ()=>{}
  },[snapValue]);
 */