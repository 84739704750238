import { filter, groupBy, includes, intersection,keys, remove} from "lodash";
import phoneHelper from "./phoneHelper";
import textHelper from "./textHelper";

class ChartHelper {

  groupData(peoples:any[],title:string,key:string):any[]|null{
    let filterYears :any[]|null = textHelper.groupPeopleBySection(title, peoples);
    const years = groupBy(filterYears,key);
    return textHelper.groupBySection(key,filterYears, years);
  }

  groupResponseImage(responses:any[],quiz_id:number,key:string):any[]|null{
    let filters :any[]|null = textHelper.groupReponseByQuizID(quiz_id, responses);
    return filters;
  }

  groupResponse(responses:any[],quiz_id:number,key:string):any[]|null{
    let filters :any[]|null = textHelper.groupReponseByQuizID(quiz_id, responses);
    const groups = groupBy(filters,key);
    return textHelper.groupBySection(key,filters, groups);
  }

  filterPeoples(peoples:any[],valueFilterSections:any[]):any[]|null{
    return filter(peoples,function (p){
       return intersection(p.sections.map((s:any)=>s.subject),valueFilterSections).length > 0;
    })
  }

  filterResponseByPeoples(peoples:any[],valueFilters:any[],key:string):any[]|null{
    return filter(peoples,function (p){
      return valueFilters.length===0 ? true : includes(valueFilters,p[key]);
   })
  }

  filterPeoplesByKey(peoples:any[],valueFilters:any[],key:string):any[]|null{
    return filter(peoples,function (p){
      return valueFilters.length===0 ? true : includes(valueFilters,p[key]); 
   })
  }

  filterPeoplesByTeleCom(peoples:any[],telecomFilters:any[],key:string):any[]|null{
    return filter(peoples,function (p){
      return telecomFilters.length===0 ? true : 
      includes(telecomFilters.map((t)=>phoneHelper.switchCasePhoneByTelecom(p[key],t)),true);
   })
  }

  filterPeoplesByRcaTeleCom(peoples:any[],telecomFilters:any[],key:string):any[]|null{
    return filter(peoples,function (p){
      return telecomFilters.length===0 ? true : 
      includes(telecomFilters.map((t)=>phoneHelper.switchCasePhoneByRcaTelecom(p[key],t)),true);
   })
  }

  getLabels(data:any[],key:string):any[]|null{
    var labels = keys(groupBy(data,key));
    var evt = remove(labels,function(n){return n=="null";});
    
    return labels;
  }

  getSeries(data:any[],key:string):any[]|null{
    let groupData = groupBy(data,key);
    var labels = keys(groupBy(data,key));

    var evt = remove(labels,function(n){return n=="null";});
    let series:any[] = [];

    labels.map((l)=>{
      series.push(groupData[l].length);
    });

    return series;
  }

  getListPeopleId(list1:any[],list2:any[],list3:any[]):any[]{
    if(list1.length>0 && list2.length>0 && list3.length>0){
      return intersection(list1,list2,list3);
    }
    else if(list1.length>0 && list2.length<=0 && list3.length<=0){
      return list1;
    }
    else if(list1.length<=0 && list2.length>0 && list3.length<=0){
      return list2;
    }
    else if(list1.length<=0 && list2.length<=0 && list3.length>0){
      return list3;
    }
    else if(list1.length>0 && list2.length>0 && list3.length<=0){
      return intersection(list1,list2);
    }
    else if(list1.length>0 && list2.length<=0 && list3.length>0){
      return intersection(list1,list3);
    }
    else if(list1.length<=0 && list2.length>0 && list3.length>0){
      return intersection(list2,list3);
    }
    else{
      return [];
    }
  }
}

export default new ChartHelper();