import { Breadcrumb, Checkbox, Divider, Layout, Typography } from 'antd';
import { filter, isEmpty } from 'lodash';
import React from 'react'
import chartHelper from '../../utils/helpers/chartHelper';
import 'scrollable-component';
import formatHelper from '../../utils/helpers/formatHelper';
import userHelper from '../../utils/helpers/userHelper';
import { useQueryClient } from '@tanstack/react-query';

export default function FilterView() {

const queryClient = useQueryClient();
const current = userHelper.getSurveyData();
const userAdmin = userHelper.getData();

const peoples = queryClient.getQueryData(['peoples']) || [];
const sections = queryClient.getQueryData(['sections']) || [];
const researchs = queryClient.getQueryData(['researchs']) || [];

const [options,setOptions] = React.useState([]);
const [gender,setGender] = React.useState([]);
const [search, setSearch] = React.useState([]);
const [obj, setObj] = React.useState({});


const [optionGenders]= React.useState([
    {"value":"M","label":"Masculin"},
    {"value":"F","label":"Féminin"}
]);
 
const onChange = (e) => {
   /* dispatch(setListPeopleId({
      "key":"section",
      "values":chartHelper.filterPeoples(
        filter(peoples,{"investigation_id":current.id}), e
    ).map((p)=>p.id)
    }));*/
};
  
const onChangeGender = (e)=>{
  setGender(e);
}

/*
React.useEffect(()=>{
  dispatch(setListPeopleId({
    "key":"gender",
    "values":chartHelper.filterPeoplesByKey(
      filter(peoples,{"investigation_id":current.id}), gender, "gender"
  ).map((p)=>p.id)
  }))

},[gender])*/

const onChangeResearch = (e, r)=>{
  setSearch(e);
  setObj(r);
}

/*
React.useEffect(()=>{
  dispatch(setListPeopleId({
    "key":formatHelper.reformObject(obj).column,
    "values": formatHelper.filterByQuery(
      filter(peoples,{"investigation_id":current.id}),
      formatHelper.reformObject(obj),
      search).map((p)=>p.id)
    })
  );

},[search,obj])*/

/*
React.useEffect(()=>{
  const opts = [];
  filter(sections,{"investigation_id":current.id})
    .map((section)=>{
      section.contents.forEach(e => {
      opts.push({"value":e.subject,"label":e.subject});
    })
  })
  setOptions(opts);
},[current,sections])*/

/*React.useEffect(()=>{
  dispatch(setListPeopleId({"key":"remove","values":[]}));
},[current]);*/

  return (
    <div style={{margin:"0px 10px 10px 10px"}}>
        <Layout.Content style={{alignItems:"center"}}>
          <Breadcrumb>
            <Breadcrumb.Item>Survey</Breadcrumb.Item>
            <Breadcrumb.Item>
             {current.sigle}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Layout.Content>
        <Divider />
          <Layout.Content style={{alignItems:"center",margin:"0px 5px"}}>
            <Typography.Title level={5} style={{margin:"0px 0px 5px 0px"}}>{"SEXE"}</Typography.Title>
            <Checkbox.Group options={optionGenders} onChange={onChangeGender} />
          </Layout.Content>
          {
            filter(researchs,{"investigation_id":current.id}).map((r,k)=>(
             <React.Fragment key={k}>
                <Layout.Content style={{alignItems:"center",margin:"5px 0px"}}>
                  <Typography.Title level={5} style={{margin:"5px 0px"}}>{r.title}</Typography.Title>
                  <Checkbox.Group options={r.fields.map((f)=>({"value":f.contents,"label":f.name}))} 
                    onChange={e=>onChangeResearch(e,r)} 
                  />
                </Layout.Content>
             </React.Fragment>
            ))
          }
          {
            options.length >0 &&  <Layout.Content style={{alignItems:"center", margin:"5px 0px"}}>
              <Typography.Title level={5} style={{margin:"5px 0px"}}>{"SECTIONS"}</Typography.Title>
              <Checkbox.Group options={options} onChange={onChange} />
            </Layout.Content>
          }
    </div>
  )
}

/**
 *  <Layout.Content style={{alignItems:"center",margin:"0px 5px"}}>
            <Typography.Title level={5} style={{margin:"5px 0px"}}>{"TELECOM RDC"}</Typography.Title>
            <Checkbox.Group options={optionPhones} onChange={onChangePhone} />
          </Layout.Content>
          <Layout.Content style={{alignItems:"center",margin:"0px 5px"}}>
            <Typography.Title level={5} style={{margin:"5px 0px"}}>{"TELECOM RCA"}</Typography.Title>
            <Checkbox.Group options={optionPhonesRca} onChange={onChangePhoneRca} />
          </Layout.Content>
 * 
 * 
 * 
 * 
 *  <scrollable-component style={{height:"80vh"}} vertical-scrollbar-position="right">
          <Layout.Content style={{alignItems:"center",margin:"0px 5px"}}>
            <Typography.Title level={5} style={{margin:"0px 0px 5px 0px"}}>{"SEXE"}</Typography.Title>
            <Checkbox.Group options={optionGenders} onChange={onChangeGender} />
          </Layout.Content>
          <Layout.Content style={{alignItems:"center",margin:"0px 5px"}}>
            <Typography.Title level={5} style={{margin:"5px 0px"}}>{"TELECOM RDC"}</Typography.Title>
            <Checkbox.Group options={optionPhones} onChange={onChangePhone} />
          </Layout.Content>
          <Layout.Content style={{alignItems:"center",margin:"0px 5px"}}>
            <Typography.Title level={5} style={{margin:"5px 0px"}}>{"TELECOM RCA"}</Typography.Title>
            <Checkbox.Group options={optionPhonesRca} onChange={onChangePhoneRca} />
          </Layout.Content>
          <Layout.Content style={{alignItems:"center", margin:"5px 0px"}}>
            <Typography.Title level={5} style={{margin:"5px 0px"}}>{"SECTIONS"}</Typography.Title>
            <Checkbox.Group options={options} onChange={onChange} />
          </Layout.Content>
      </scrollable-component>
 */