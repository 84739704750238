import { isEmpty, upperFirst } from "lodash";
import phoneHelper from "./phoneHelper";

class TextHelper {
  groupByNetwork(data:any[]): any | null {
    let voda :number =0;
    let afr : number =0;
    let airtel: number =0;
    let orange: number =0;
    let other : number =0;
    let response: any[] = [];
    data.map((e)=>{
        if(phoneHelper.vodaRexp(e.phone)){
            voda ++;
        }
        else if(phoneHelper.orangeRexp(e.phone)){
            orange++;
        }
        else if(phoneHelper.africellRexp(e.phone)){
            afr ++;
        }
        else if(phoneHelper.airtelRexp(e.phone)){
            airtel ++;
        }
        else if(phoneHelper.otherRexp(e.phone)){
            other++;
        }
    });
    response.push({r:"voda",n:voda});
    response.push({r:"orange",n:orange});
    response.push({r:"africell",n:afr});
    response.push({r:"airtel",n:airtel});
    response.push({r:"other",n:other});

    return response;
  }

  groupBySection(key:string,data:any[],group:any):any[]{
    let reponses: any[] = [];
    data.map((section)=>{
        reponses.push({type:section[key],value:isEmpty(group[section[key]]) ? 0 : group[section[key]].length});
    });
    return reponses;
  }

  groupPeopleBySection(title:string,peoples:any[]):any[]{
    let filter :any[] = [];
    peoples.map((people:any)=>{
        people.sections.map((section:any)=>{
           if(section.title ===title){
            filter.push(section);
           }
         });
     });
    return filter;
  }

  groupReponseByQuizID(quiz_id:number,responses:any[]):any[]{
    let filter :any[] = [];
    responses.map((response:any)=>{
       if(response.quiz_id ===quiz_id){
          filter.push(response);
        }
     });
    return filter;
  }

  groupSectionByKey(key:string,data:any[],group:any,xKey:string,yKey:string):any[]|null{
    let reponses: any[] = [];
    data.map((section)=>{
        reponses.push({type:section[key],value:isEmpty(group[section[key]]) ? 0 : group[section[key]].length});
    });
    return reponses;
  }

  getNameUser(user:any):string{
    return !isEmpty(user) ? upperFirst(user.first_name) + " "+upperFirst(user.last_name) : "";
  }

}

export default new TextHelper();
