import { isEmpty} from "lodash";

const appName = "WiiCollect";
const refKey = "iag-ref";

const setAppTitle = (value: string) => {
  document.title = `${appName}${!isEmpty(value) ? " | " : ""}${value}`;
};

const getUid = (value: string): string => {
  if (typeof value !== "string") {
    return "";
  }
  if (isEmpty(value)) {
    return "";
  }
  let response = "";
  const items = value.split("/");
  if (items.length > 0) {
    response = items[items.length - 1];
  }
  return response;
};

export function cleanHTML(str: string) {
  let response = "";
  if (!isEmpty(str)) {
    str = str.toString();
    response = str.replace(/<[^>]*>/g, "");
  }
  return response;
}

export { appName, getUid, refKey, setAppTitle };
