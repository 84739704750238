import { isEmpty} from "lodash";
import React, { Suspense } from "react";
import { Navigate, Route, Routes, useLocation} from "react-router-dom";
import { Navbar } from "../../components/navigation/me/navbar";
import { routes } from "../../routes";

import { setAppTitle } from "../../utils/helpers/title";
import userHelper from "../../utils/helpers/userHelper";
import { NotFoundScreen } from "../404";
import { FallbackScreen } from "../fallback";

export function MeLayout() {
  const userAdmin = userHelper.getData();
  const currentSurvey = userHelper.getSurveyData();

  const routerLocation = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [routerLocation]);

  if (isEmpty(userAdmin)) {
    return (
      <Navigate
        to="/"
        replace
        state={{ message: "Vous devez vous connecter avant tout" }}
      />
    );
  }

  if (isEmpty(currentSurvey)) {
    return (
      <Navigate
        to="/home/"
        replace
        state={{ message: "Vous devez choisir une enquête" }}
      />
    );
  }

  setAppTitle("Survey");

  return (
    <React.Fragment>
     
      <Navbar key="nav-admin">
       <Suspense fallback={<FallbackScreen />}>
        <Routes>
           {routes.filter(
              (p) =>
                p.layout === "/me" &&
                userHelper.isVisible(p.visibility, userAdmin, p.subscribeable, true)
              ).map((p, index)=>{
                return (
                  <Route
                    path={`${p.path}`}
                    element={<p.component />}
                    key={`${p.name}${index}`}
                  />
                );
              })}
            <Route path={`*`} element={<NotFoundScreen />} key={`notfound`} />
         </Routes>
        </Suspense>
      </Navbar>
    </React.Fragment>
  );
}