import axios from "axios";
import { isEmpty } from "lodash";
import userHelper from "../utils/helpers/userHelper";
import config from "./config";

export const http = axios.create({
  baseURL: config.URL_API,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "crossDomain": true,
    "Accept": "*/*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Authorization, X-Auth-Token",
  }
});

export const CancelToken = axios.CancelToken;

const setAuthHeader = (config: any) => {

  //const token = isEmpty(userHelper.getToken()) ? "176|0tyxS6HqU07zT5zMszuyzXVUHHkZ6J8nLE0DUbYt" : userHelper.getToken();
  //const token = isEmpty(userHelper.getToken()) ? "516|gpotUaQyeIiECfALagBAQ7s0kzbWxUBAsNcyGWvS" : userHelper.getToken(); // api.icollect
  //const token = isEmpty(userHelper.getToken()) ? "184|0Znp2Mxawp4XmwoQ0vxvI4yUExpLfQlfSkRuHwHR" : userHelper.getToken(); //api local
  const token = isEmpty(userHelper.getToken()) ? "184|1nYHGlOz2VNQNDynd2C41g913t6OBtDDkmkS4uji" : userHelper.getToken(); //api-prod
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  } else {
    config.cancelToken = new CancelToken((cancel) => cancel("No token"));
  }
  return config;
};

//http.defaults.headers.get['Access-Control-Allow-Origin'] = "*";

http.interceptors.request.use(setAuthHeader);

export default http;
